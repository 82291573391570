.App {
  font-family: sans-serif;
  text-align: center;
}

foreignObject {
  overflow: visible;
}

.treeWrapper {
  display: block;
  height: 32vh;
  width: 100%;
  margin: 0 auto;
  
}

.label {
  -webkit-font-smoothing: antialiased;
  display: block;
  height: 70px;
  width: 240px;
  border: solid 1px #919192;
  border-left: solid 4px #919192;
  border-radius: 4px;
  padding: 0;
  background-color: white;
  color: #4a4a4a;
  z-index: 1;
  stroke-width: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
}
.label img {
  display: inline-block;
  float: left;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  z-index: 5;
  background-color: #f3f3f3;
}

.label a {
  color: #4a4a4a;
  text-decoration: none;
  transition: 300ms;
}
 

.linkBase {
  stroke: #919192;
}

.node-info {
  width: 150px;
  float: right;
  height: 80px;
}

.node-title {
  max-width: 144px;
  height: 30px;
  padding: 8px 0;
  overflow: hidden;
  white-space: normal;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}

.linesEllipsis {
  max-width: 144px;
  text-overflow: ellipsis;
}

.role {
  display: block;
  top: 10px;
  float: left;

  font-weight: bold;
  font-size: 9.3px;
}

.participants {
  display: block;
  margin-top: 18px;
  padding-right: 4px;
  white-space: nowrap;
  font-size: 9px;
  font-weight: normal;
  text-align: right;
}

.accepting {
  margin-top: 18px;
  display: inline-block;
  float: left;
  font-weight: normal;
  margin-left: -20px;
  font-size: 9px;
}

.appt_status {
  width: 24px;
  float: right;
  height: 16px;
  margin-top: 14px;
  margin-right: 8px;
  border-radius: 2px;
  padding: 4px;
  color: white;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 8px;
}

.toggleContainer {
  display: block;
  box-sizing: border-box;
  margin-left: 92px;
  content: "";
  width: 50px;
  height: 18px;
 
  border-top: none;
  background-color: white;
  clear: both;
 
}
.toggleContainer .children {
  margin: 0;
  padding: 0 4px 2px 8px;
  float: left;
  font-weight: normal;
}
.toggleContainer .toggle {
  float: right;
  margin: 0 auto;
  padding: 0 8px 2px 4px;
  font-size: 12px;
  color: #ffc20d;
}

.activeNode {
  border-color: #ffc20d;
}
.activeNode .toggleContainer {
  border-color: #ffc20d;
}
