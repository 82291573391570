@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    li {
        @apply p-6 hover:text-black ;
    }



    label {
        @apply block text-gray-700 text-sm font-bold mb-2;
    }

    input {
        @apply bg-white border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal;
    }

    textarea {
        @apply bg-white border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal w-full h-full;
    }

    .smol-input {
        @apply w-20 h-10 rounded-lg py-2 px-4 block appearance-none leading-tight;
    }

    .title {
        @apply text-3xl font-extrabold tracking-tight text-slate-900;
    }

    /* button { */
    /*     @apply text-black border bg-green-400 border-green-400 hover:bg-transparent hover:text-green-400 rounded-md px-10 py-4; */
    /* } */
}
